/* eslint-disable no-console */
/* eslint-disable comma-dangle */
import type { Feature } from './types';

import chain from '../chain';
import { getEnvValue } from '../utils';

const walletConnectProjectId = getEnvValue(
  'NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID'
);

const title = 'Blockchain interaction (writing to contract, etc.)';

const config: Feature<{ walletConnect: { projectId: string } }> = (() => {
  // console.log('NEXT_PUBLIC_NETWORK_ID', chain.id);
  // console.log('NEXT_PUBLIC_NETWORK_NAME', chain.name);
  // console.log('NEXT_PUBLIC_NETWORK_CURRENCY_NAME', chain.currency.name);
  // console.log('NEXT_PUBLIC_NETWORK_CURRENCY_SYMBOL', chain.currency.symbol);
  // console.log('NEXT_PUBLIC_NETWORK_CURRENCY_DECIMALS', chain.currency.decimals);
  // console.log('NEXT_PUBLIC_NETWORK_RPC_URL', chain.rpcUrl);
  // console.log('NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID', walletConnectProjectId);
  // console.log(
  //   chain.id &&
  //     chain.name &&
  //     chain.currency.name &&
  //     chain.currency.symbol &&
  //     chain.currency.decimals &&
  //     chain.rpcUrl &&
  //     walletConnectProjectId
  // );

  if (
    // all chain parameters are required for wagmi provider
    // @wagmi/chains/dist/index.d.ts
    chain.id &&
    chain.name &&
    chain.currency.name &&
    chain.currency.symbol &&
    chain.currency.decimals &&
    chain.rpcUrl &&
    walletConnectProjectId
  ) {
    return Object.freeze({
      title,
      isEnabled: true,
      walletConnect: {
        projectId: walletConnectProjectId,
      },
    });
  }

  return Object.freeze({
    title,
    isEnabled: false,
  });
})();

export default config;
