import {
  Box,
  Image,
  useColorModeValue,
  Skeleton,
  chakra,
  Flex,
  Text,
} from '@chakra-ui/react';
import React from 'react';

import { route } from 'nextjs-routes';

import config from 'configs/app';
import IconSvg from 'ui/shared/IconSvg';

interface Props {
  isCollapsed?: boolean;
  onClick?: (event: React.SyntheticEvent) => void;
  className?: string;
}

const LogoFallback = ({
  isCollapsed,
  isSmall,
}: {
  isCollapsed?: boolean;
  isSmall?: boolean;
}) => {
  const field = isSmall ? 'icon' : 'logo';

  const display = isSmall ?
    {
      base: 'none',
      lg: isCollapsed === false ? 'none' : 'block',
      xl: isCollapsed ? 'block' : 'none',
    } :
    {
      base: 'block',
      lg: isCollapsed === false ? 'block' : 'none',
      xl: isCollapsed ? 'none' : 'block',
    };

  if (config.UI.sidebar[field].default) {
    return <Skeleton w="100%" borderRadius="sm" display={ display }/>;
  }

  return (
    <IconSvg
      name={ isSmall ? 'networks/icon-placeholder' : 'networks/logo-placeholder' }
      width="auto"
      height="100%"
      color="greenFork.500"
      display={ display }
    />
  );
};

const NetworkLogo = ({ isCollapsed, onClick, className }: Props) => {
  const logoSrc = useColorModeValue(
    config.UI.sidebar.logo.default,
    config.UI.sidebar.logo.dark || config.UI.sidebar.logo.default,
  );
  // const darkModeFilter = { filter: 'brightness(0) invert(1)' };
  // const logoStyle = useColorModeValue({}, !config.UI.sidebar.logo.dark ? darkModeFilter : {});
  // const iconStyle = useColorModeValue({}, !config.UI.sidebar.icon.dark ? darkModeFilter : {});

  return (
    <Box
      className={ className }
      as="a"
      href={ route({ pathname: '/' }) }
      // width={{ base: '120px', lg: isCollapsed === false ? '120px' : '30px', xl: isCollapsed ? '30px' : '120px' }}
      height="39px"
      display="inline-flex"
      overflow="hidden"
      onClick={ onClick }
      flexShrink={ 0 }
      aria-label="Link to main page"
    >
      { /* big logo */ }
      <Flex gap="10px" alignItems="center">
        <Image
          w="auto"
          h="100%"
          src={ logoSrc }
          alt={ `${ config.chain.name } network logo` }
          fallback={ <LogoFallback isCollapsed={ isCollapsed }/> }
          display="block"
          // style={ logoStyle }
        />
        { !isCollapsed ? (
          <Text fontSize={ 22 } lineHeight="23px" fontWeight={ 600 }>
            CratD2C
          </Text>
        ) : null }
      </Flex>
    </Box>
  );
};

export default React.memo(chakra(NetworkLogo));
