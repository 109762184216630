import { Icon, Link } from '@chakra-ui/react';
import React from 'react';
import type { IconType } from 'react-icons';

type Props = {
  link: string;
  icon: IconType;
}

export const FooterLinkItem = ({ link, icon }: Props) => {

  return (
    <Link href={ link } display="flex" flexDirection="column" alignItems="center">
      <Icon as={ icon } boxSize="22px"/>
    </Link>
  );
};
