import type { FlexProps } from '@chakra-ui/react';
import { Box, Flex, Hide, Image, Link, Show } from '@chakra-ui/react';
import React from 'react';
import {
  FaDiscord,
  FaFacebook,
  FaLinkedinIn,
  FaTelegramPlane,
  FaTwitter,
  FaYoutube,
} from 'react-icons/fa';

import { FooterLinkItem } from './FooterLinkItem';
import {
  certikLink,
  dsCratD2CLink,
  facebookCratD2CLink,
  hackenLink,
  linkedinCratD2CLink,
  tgCratD2CLink,
  twCratD2CLink,
  youtubeCratD2CLink,
} from './utils/footerLinks';

const PartnerLinks = () => {
  return (
    <Flex gap="30px">
      <Link href={ certikLink }>
        <Image
          src="/static/CertikLogo.png"
          alt="CertikLogo"
          w="100px"
          h="23px"
        />
      </Link>
      <Link href={ hackenLink }>
        <Image
          src="/static/HackenLogo.png"
          alt="HackenLogo"
          w="100px"
          h="30px"
        />
      </Link>
    </Flex>
  );
};

const FooterLinkList = () => {
  const linksToMap = [
    { link: tgCratD2CLink, icon: FaTelegramPlane },
    { link: dsCratD2CLink, icon: FaDiscord },
    { link: twCratD2CLink, icon: FaTwitter },
    { link: linkedinCratD2CLink, icon: FaLinkedinIn },
    { link: youtubeCratD2CLink, icon: FaYoutube },
    { link: facebookCratD2CLink, icon: FaFacebook },
  ];

  return (
    <Flex gap="30px">
      { linksToMap.map(({ link, icon }) => (
        <FooterLinkItem link={ link } icon={ icon } key={ link }/>
      )) }
    </Flex>
  );
};

const CopyrightByCratD2C = () => {
  return <Box>Copyright © { new Date().getFullYear() } CratD2C</Box>;
};

const Footer = () => {
  const containerProps: FlexProps = {
    as: 'footer',
    px: { base: 4, lg: 12 },
    py: { base: 4, lg: 8 },
    borderTop: '1px solid',
    borderColor: 'divider',
    display: 'flex',
    alignItems: 'center',
  };

  return (
    <>
      <Show above="lg">
        <Flex { ...containerProps } justifyContent="space-between">
          <CopyrightByCratD2C/>
          <Flex gap="30px" alignItems="center">
            <PartnerLinks/>
            <FooterLinkList/>
          </Flex>
        </Flex>
      </Show>
      <Hide above="lg">
        <Flex
          { ...containerProps }
          gap="30px"
          flexDirection="column"
          justifyContent="center"
        >
          <CopyrightByCratD2C/>
          <PartnerLinks/>
          <FooterLinkList/>
        </Flex>
      </Hide>
    </>
  );
};

export default React.memo(Footer);
